import { atom } from "recoil";

// Nascent の セール情報
export const saleState = atom({
  key: "Sale",
  default: {
    stockCount: 0,
    publicSalePrice: "",
    publicSaleStartTime: 0,
    revealStartTime: 0,
    totalMintCount: 0,
    whitelistSaleEndTime: 0,
    whitelistSalePrice: "",
    whitelistSaleStartTime: 0,
  },
});
