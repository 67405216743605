import Link from "next/link";
import { ReactNode } from "react";
import styles from "./index.module.scss";

const PrimaryAnimatedBtn = ({
  onClick,
  children,
  extraClass,
  href,
  disabled = false,
}: {
  onClick?: () => void;
  children: ReactNode;
  extraClass?: string;
  href?: string;
  disabled?: boolean;
}) =>
  href ? (
    <Link href={href} passHref>
      <a
        className={`${styles["button"]} bg-[#00acb399] ${
          extraClass ? extraClass : ""
        }`}
      >
        {children}
      </a>
    </Link>
  ) : (
    <button
      type="button"
      onClick={disabled ? () => {} : onClick}
      className={`${styles["button"]} bg-[#00acb399] ${
        extraClass ? extraClass : ""
      }`}
      disabled={disabled}
    >
      {children}
    </button>
  );

export default PrimaryAnimatedBtn;
