import OpenSeaSvg from "../ui/OpenSeaSvg";
import PrimaryAnimatedBtn from "../ui/PrimaryAnimatedBtn";
import styles from "./header.module.scss";

type Props = {
  /**
   * BUY
   */
  onClickBuyButton: () => void;
};

const HeaderBuy = ({ onClickBuyButton }: Props) => {
  return (
    <nav className={styles["buy-nav"]}>
      <PrimaryAnimatedBtn onClick={onClickBuyButton} extraClass="px-[24px]">
        <span className={styles["buy-text"]}>BUY NFT</span>
        <OpenSeaSvg />
      </PrimaryAnimatedBtn>
    </nav>
  );
};

export default HeaderBuy;
