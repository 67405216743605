import { useRouter } from "next/router";
import { ReactNode, useState } from "react";
import { useMedia } from "react-use";
import { useRecoilValue } from "recoil";
import { MOBILE_MAX_WIDTH } from "../../../constant";
import { saleState } from "../../../store/sale";
import CloseBtn from "../ui/CloseBtn";
import PrimaryAnimatedBtn from "../ui/PrimaryAnimatedBtn";
import RightArrow from "../ui/RIghtArrow";

type Props = {
  children?: ReactNode;
  onCancel: any;
};

const MINT_PAGE_PATH = "/mint";

export const FooterFixed = ({ onCancel }: Props) => {
  const router = useRouter();
  const [isClose, setIsClose] = useState(false);
  const isMobile = useMedia(`(max-width: ${MOBILE_MAX_WIDTH}px)`, false);
  // Nascent セール情報
  const sale = useRecoilValue(saleState);

  const locationToMint = () => {
    location.href = MINT_PAGE_PATH;
  };

  if (
    isClose ||
    router.asPath.startsWith(MINT_PAGE_PATH) ||
    sale.stockCount === 0 ||
    new Date() < new Date(sale.whitelistSaleStartTime)
  )
    return <></>;

  return (
    <div
      className={`z-10 fixed bottom-0 left-0 w-full bg-[#141414] border-t border-white ${
        isMobile ? "h-[128px] p-[16px]" : "h-[124px]"
      }`}
    >
      <div
        className={`h-full flex text-white justify-between items-center ${
          isMobile ? "flex-col" : "px-[310px] xl:px-[160px] lg:px-[72px] "
        }`}
      >
        <div
          className={`flex ${
            isMobile
              ? "flex-col text-center gap-[4px]"
              : "gap-[40px] lg:gap-[12px] items-center"
          }`}
        >
          <div
            className={`font-bold ${
              isMobile
                ? "text-[16px] leading-[16px]"
                : "text-[28px] leading-[28px]"
            }`}
          >
            『NEURAL ELECTIVE N Nascent』
          </div>
          <div
            className={`font-bold ${
              isMobile
                ? "text-[20px] leading-[20px]"
                : "text-[36px] leading-[36px]"
            }`}
          >
            NOW ON SALE
          </div>
        </div>
        <PrimaryAnimatedBtn
          onClick={locationToMint}
          extraClass="px-[90px] lg:px-[40px]"
        >
          <span className="mr-[8px] font-bold text-[20px] leading-[20px] lg:text-[16px] lg:leading-[16px]">
            MINT PAGE
          </span>
          <RightArrow />
        </PrimaryAnimatedBtn>
      </div>
      <CloseBtn
        onClick={() => setIsClose(true)}
        extraClass={`absolute top-[8px] ${
          isMobile ? "right-[8px]" : "right-[40px]"
        }`}
      />
    </div>
  );
};
