const RightArrow = () => (
  <div className="w-[24px] h-[24px] flex justify-center items-center">
    <svg
      width="12"
      height="19"
      viewBox="0 0 12 19"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.585786 1.41421L9.65685 10.4853L11.0711 9.07107L2 0L0.585786 1.41421Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 18.1421L9.65685 10.4853L8.24264 9.07107L0.585786 16.7279L2 18.1421Z"
      />
    </svg>
  </div>
);

export default RightArrow;
