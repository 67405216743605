import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useMedia } from "react-use";
import { useRecoilState } from "recoil";
import { MOBILE_MAX_WIDTH } from "../../../constant";
import { languageState } from "../../../store/language";
import { LanguageCode } from "../../../types/languageType";

import HeaderBuy from "./buy";
import HeaderLanguage from "./language";
import HeaderMenu from "./menu";

import { useTranslation } from "../../../services/i18n";
import CloseBtn from "../ui/CloseBtn";
import styles from "./header.module.scss";

const Header = () => {
  const router = useRouter();
  const t = useTranslation();
  // mq
  const isMobile = useMedia(`(max-width: ${MOBILE_MAX_WIDTH}px)`, false);

  // メニューボタン
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

  const [language, setLanguage] = useRecoilState(languageState);
  const [isJapanese, setIsJapanese] = useState(false);

  const toggleMenu = () => {
    setIsHamburgerOpen((prev) => !prev);
  };

  const handleLanguage = (_language: LanguageCode) => {
    setIsHamburgerOpen(false);
    setLanguage(_language);
  };

  // 購入ボタン
  const handleBuy = () => {
    const buyPath = t("buyNowLink") as string;
    window.open(buyPath, "_blank");
  };

  // 言語切り替え
  useEffect(() => {
    setIsJapanese(language === "ja");
  }, [language]);

  // ページ変更時にメニューを閉じる
  useEffect(() => {
    setIsHamburgerOpen(false);
  }, [router]);

  return (
    <>
      <header className={styles.header}>
        {isMobile ? (
          <>
            <div className={`z-10 ${styles["header-inner"]}`}>
              <LogoLink className="w-[40px] h-[40px] relative" />
              {/* ナビゲーションメニュー */}
              <div className={styles["nav-wrapper"]}>
                <HeaderLanguage
                  isJapanese={isJapanese}
                  setLanguage={setLanguage}
                />
                <Hamburger onClick={toggleMenu} />
              </div>
            </div>

            <div
              className={`z-50 ${styles["menu-modal"]} ${
                isHamburgerOpen && styles["modal-show"]
              }`}
            >
              <div className={styles["menu-modal-header"]}>
                <LogoLink className="w-[48px] h-[48px] relative" />
                <CloseBtn onClick={toggleMenu} />
              </div>
              {/* ナビゲーションメニュー */}
              <HeaderMenu isOpenReveal={true} />
              <HeaderBuy onClickBuyButton={handleBuy} />
            </div>
          </>
        ) : (
          <div className={styles["header-inner"]}>
            <LogoLink className="w-[48px] h-[36px] relative" />
            {/* ナビゲーションメニュー */}
            <div className={`${styles["nav-wrapper"]}`}>
              <HeaderMenu isOpenReveal={true} />
              <HeaderLanguage
                isJapanese={isJapanese}
                setLanguage={handleLanguage}
              />
              <HeaderBuy onClickBuyButton={handleBuy} />
            </div>
          </div>
        )}
      </header>
    </>
  );
};

const LogoLink = ({ className }: { className?: string }) => (
  <Link href={"/"} passHref>
    <a className={className}>
      <Image
        src="/images/logo.png"
        alt="Neural Elective N"
        layout="fill"
        objectFit="contain"
      />
    </a>
  </Link>
);

const Hamburger = ({ onClick }: { onClick: () => void }) => {
  return (
    <button type="button" className={styles.hamburger} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="14"
        viewBox="0 0 20 14"
        fill="white"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 2V0H20V2H0ZM0 8H20V6H0V8ZM0 14H20V12H0V14Z"
        />
      </svg>
    </button>
  );
};

export default Header;
