import Link from "next/link";
import { useState } from "react";
import { useMedia } from "react-use";
import { MOBILE_MAX_WIDTH } from "../../../constant";
import PlusSvg from "../ui/PlusSvg";
import styles from "./accordion.module.scss";

const AccordionHeader = ({
  title,
  titleHref,
  isOpen,
  isActive,
  isMobile,
  isHovered,
}: {
  title: string;
  titleHref: string;
  isOpen: boolean;
  isActive: boolean;
  isMobile: boolean;
  isHovered: boolean;
}) => {
  let className = styles["accordion-header-text"];
  if (isMobile && isHovered) className += " " + styles["is-wrapper-hovered"];

  if (!isMobile && isOpen)
    return (
      <Link href={titleHref} passHref>
        <a className={className}>{title}</a>
      </Link>
    );

  return (
    <button className={className}>
      {title}
      {isMobile && <Plus isFilled={isHovered} />}
    </button>
  );
};

const Plus = ({ isFilled }: { isFilled: boolean }) => (
  <div className="w-[24px] h-[24px] flex justify-center items-center">
    <PlusSvg fill={isFilled ? "#00acb3" : "#fff"} />
  </div>
);

export const Accordion = ({
  title,
  titleHref,
  isOpen = false,
  isActive,
  contents,
}: {
  title: string;
  titleHref: string;
  isOpen: boolean;
  isActive: boolean;
  contents: { href: string; text: string }[];
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const isMobile = useMedia(`(max-width: ${MOBILE_MAX_WIDTH}px)`, false);

  return (
    <span
      className={isMobile ? "w-full" : ""}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <AccordionHeader
        title={title}
        titleHref={titleHref}
        isOpen={isOpen}
        isActive={isActive}
        isMobile={isMobile}
        isHovered={isHovered}
      />
      <div
        className={`${styles["accordion-items-outer-wrapper"]} ${
          isOpen ? styles["is-open"] : ""
        }`}
      >
        <ul className={styles["accordion-items-wrapper"]}>
          {contents.map((content, i) => (
            <li key={i} className={styles["accordion-item"]}>
              <Link href={content.href} passHref>
                <a>{content.text}</a>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </span>
  );
};
