import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AnswerUpdateHistory = {
  __typename: "AnswerUpdateHistory";
  answer: Scalars["String"];
  createdAt: Scalars["DateTime"];
  createdTxHash: Scalars["String"];
  id: Scalars["ID"];
  owner: Scalars["String"];
  ownerCredit: Scalars["String"];
  tokenId: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
};

export type Attribute = {
  __typename: "Attribute";
  content: Scalars["String"];
  count: Scalars["Int"];
  label: Scalars["String"];
};

export type CountDto = {
  __typename: "CountDto";
  mintedCount: Scalars["Int"];
};

export enum GeneType {
  Adenine = "ADENINE",
  Thymine = "THYMINE",
}

export type GetParamsForMint = {
  __typename: "GetParamsForMint";
  isWhitelistSale: Scalars["Boolean"];
  serverSig: Sig;
};

export type GetParamsForSecondMint = {
  __typename: "GetParamsForSecondMint";
  isWhitelistSale: Scalars["Boolean"];
  maxMintCount: Scalars["Int"];
  mintedCount: Scalars["Int"];
  proof: Array<Scalars["String"]>;
};

export type GetParamsForUpdateAnswerWithAttribute = {
  __typename: "GetParamsForUpdateAnswerWithAttribute";
  attribute: NftAttribute;
  serverSig: Sig;
};

export type Mutation = {
  __typename: "Mutation";
  updateAnswer: SecondNft;
};

export type MutationUpdateAnswerArgs = {
  answer: Scalars["String"];
  clientSig: Scalars["String"];
  credit: Scalars["String"];
  tokenId: Scalars["Float"];
};

export type Nft = {
  __typename: "Nft";
  answer?: Maybe<Scalars["String"]>;
  answerUpdateHistories?: Maybe<Array<AnswerUpdateHistory>>;
  attributeClothing: Scalars["String"];
  attributeDna: Scalars["String"];
  attributeEar: Scalars["String"];
  attributeEyes: Scalars["String"];
  attributeFace: Scalars["String"];
  attributeHairBack: Scalars["String"];
  attributeHairFront: Scalars["String"];
  attributeMouth: Scalars["String"];
  attributeNeck: Scalars["String"];
  attributeSkinTone: Scalars["String"];
  attributeSpecial: Scalars["String"];
  credit?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  imageId: Scalars["String"];
  isAnswerLocked: Scalars["Boolean"];
  isAttributeSet: Scalars["Boolean"];
  owner: Scalars["String"];
  question: Scalars["String"];
  questionCategory?: Maybe<QuestionCategory>;
  tokenId?: Maybe<Scalars["String"]>;
};

export type NftAttribute = {
  __typename: "NftAttribute";
  attributeClothing: Scalars["String"];
  attributeDna: Scalars["String"];
  attributeEar: Scalars["String"];
  attributeEyes: Scalars["String"];
  attributeFace: Scalars["String"];
  attributeHairBack: Scalars["String"];
  attributeHairFront: Scalars["String"];
  attributeMouth: Scalars["String"];
  attributeNeck: Scalars["String"];
  attributeSkinTone: Scalars["String"];
  attributeSpecial: Scalars["String"];
  question: Scalars["String"];
  questionCategory: QuestionCategory;
};

export type NftConnection = {
  __typename: "NftConnection";
  edges?: Maybe<Array<NftEdge>>;
  pageInfo?: Maybe<PageInfo>;
};

export type NftEdge = {
  __typename: "NftEdge";
  cursor: Scalars["String"];
  node: Nft;
};

export type NftFilterInput = {
  attributeClothing?: InputMaybe<Scalars["String"]>;
  attributeDna?: InputMaybe<Scalars["String"]>;
  attributeEar?: InputMaybe<Scalars["String"]>;
  attributeEyes?: InputMaybe<Scalars["String"]>;
  attributeFace?: InputMaybe<Scalars["String"]>;
  attributeHairBack?: InputMaybe<Scalars["String"]>;
  attributeHairFront?: InputMaybe<Scalars["String"]>;
  attributeMouth?: InputMaybe<Scalars["String"]>;
  attributeNeck?: InputMaybe<Scalars["String"]>;
  attributeSkinTone?: InputMaybe<Scalars["String"]>;
  attributeSpecial?: InputMaybe<Scalars["String"]>;
  credit?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  owner?: InputMaybe<Scalars["String"]>;
  questionCategory?: InputMaybe<QuestionCategory>;
  tokenId?: InputMaybe<Scalars["Int"]>;
};

export type NftSortInput = {
  createdAt?: InputMaybe<Scalars["String"]>;
  tokenId?: InputMaybe<Scalars["String"]>;
};

export type PageInfo = {
  __typename: "PageInfo";
  endCursor?: Maybe<Scalars["String"]>;
  hasNextPage: Scalars["Boolean"];
  hasPreviousPage: Scalars["Boolean"];
  startCursor?: Maybe<Scalars["String"]>;
};

export type Query = {
  __typename: "Query";
  attributes: Array<Attribute>;
  getParamsForMint: GetParamsForMint;
  getParamsForSecondMint: GetParamsForSecondMint;
  getParamsForUpdateAnswerWithAttribute: GetParamsForUpdateAnswerWithAttribute;
  isOnWhitelist: IsOnWhitelist;
  mintRequestsCount: CountDto;
  nfts: NftConnection;
  sale: Sale;
  secondAttributes: Array<SecondAttribute>;
  secondNfts: SecondNftConnection;
  secondSale: SecondSale;
  whitelist: Whitelist;
};

export type QueryGetParamsForMintArgs = {
  clientSig?: InputMaybe<Scalars["String"]>;
  count: Scalars["Int"];
  recaptchaToken: Scalars["String"];
};

export type QueryGetParamsForSecondMintArgs = {
  clientSig?: InputMaybe<Scalars["String"]>;
  count: Scalars["Int"];
  recaptchaToken: Scalars["String"];
};

export type QueryGetParamsForUpdateAnswerWithAttributeArgs = {
  tokenId: Scalars["Int"];
};

export type QueryIsOnWhitelistArgs = {
  address: Scalars["String"];
};

export type QueryNftsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<NftFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: NftSortInput;
};

export type QuerySecondNftsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<SecondNftFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: SecondNftSortInput;
};

export enum QuestionCategory {
  Animal = "ANIMAL",
  Anime = "ANIME",
  Beverage = "BEVERAGE",
  Book = "BOOK",
  Child = "CHILD",
  Color = "COLOR",
  Dislike = "DISLIKE",
  Event = "EVENT",
  Experience = "EXPERIENCE",
  Flavor = "FLAVOR",
  Flower = "FLOWER",
  Food = "FOOD",
  Fruit = "FRUIT",
  Game = "GAME",
  Hobby = "HOBBY",
  Holidays = "HOLIDAYS",
  HouseholdChore = "HOUSEHOLD_CHORE",
  Human = "HUMAN",
  If = "IF",
  Like = "LIKE",
  Manga = "MANGA",
  Movie = "MOVIE",
  Music = "MUSIC",
  Nft = "NFT",
  PersonalIntroduction = "PERSONAL_INTRODUCTION",
  Place = "PLACE",
  Routine = "ROUTINE",
  Season = "SEASON",
  Sf = "SF",
  Shopping = "SHOPPING",
  Smell = "SMELL",
  Snack = "SNACK",
  Sns = "SNS",
  Sports = "SPORTS",
  Study = "STUDY",
  Subject = "SUBJECT",
  Tech = "TECH",
  Transportation = "TRANSPORTATION",
  Travel = "TRAVEL",
  Tv = "TV",
  Vegetable = "VEGETABLE",
  Vtuber = "VTUBER",
  Web3 = "WEB3",
  Word = "WORD",
  Work = "WORK",
}

export type Sale = {
  __typename: "Sale";
  publicSalePrice: Scalars["String"];
  publicSaleStartTime: Scalars["Float"];
  revealStartTime: Scalars["Float"];
  stockCount: Scalars["Float"];
  totalMintCount: Scalars["Float"];
  whitelistSaleEndTime: Scalars["Float"];
  whitelistSalePrice: Scalars["String"];
  whitelistSaleStartTime: Scalars["Float"];
};

export type SecondAnswerUpdateHistory = {
  __typename: "SecondAnswerUpdateHistory";
  answer: Scalars["String"];
  createdAt: Scalars["DateTime"];
  createdTxHash: Scalars["String"];
  id: Scalars["ID"];
  owner: Scalars["String"];
  ownerCredit: Scalars["String"];
  tokenId: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
};

export type SecondAttribute = {
  __typename: "SecondAttribute";
  content: Scalars["String"];
  count: Scalars["Int"];
  label: Scalars["String"];
};

export type SecondNft = {
  __typename: "SecondNft";
  answer?: Maybe<Scalars["String"]>;
  attributeBackground: Scalars["String"];
  attributeCostume: Scalars["String"];
  attributeEar: Scalars["String"];
  attributeEye: Scalars["String"];
  attributeFace: Scalars["String"];
  attributeHairBack: Scalars["String"];
  attributeHairFront: Scalars["String"];
  attributeMouth: Scalars["String"];
  attributeNeck: Scalars["String"];
  attributeSkin: Scalars["String"];
  attributeSpecial: Scalars["String"];
  credit?: Maybe<Scalars["String"]>;
  geneType: Scalars["String"];
  id: Scalars["ID"];
  imageId: Scalars["String"];
  owner: Scalars["String"];
  question: Scalars["String"];
  questionCategory: Scalars["String"];
  secondAnswerUpdateHistories?: Maybe<Array<SecondAnswerUpdateHistory>>;
  tokenId?: Maybe<Scalars["String"]>;
};

export type SecondNftConnection = {
  __typename: "SecondNftConnection";
  edges?: Maybe<Array<SecondNftEdge>>;
  pageInfo?: Maybe<PageInfo>;
};

export type SecondNftEdge = {
  __typename: "SecondNftEdge";
  cursor: Scalars["String"];
  node: SecondNft;
};

export type SecondNftFilterInput = {
  credit?: InputMaybe<Scalars["String"]>;
  geneType?: InputMaybe<GeneType>;
  id?: InputMaybe<Scalars["ID"]>;
  owner?: InputMaybe<Scalars["String"]>;
  questionCategory?: InputMaybe<QuestionCategory>;
  tokenId?: InputMaybe<Scalars["Int"]>;
};

export type SecondNftSortInput = {
  createdAt?: InputMaybe<Scalars["String"]>;
  tokenId?: InputMaybe<Scalars["String"]>;
};

export type SecondSale = {
  __typename: "SecondSale";
  publicSalePrice: Scalars["String"];
  publicSaleStartTime: Scalars["Float"];
  revealStartTime: Scalars["Float"];
  stockCount: Scalars["Float"];
  totalMintCount: Scalars["Float"];
  whitelistSaleEndTime: Scalars["Float"];
  whitelistSalePrice: Scalars["String"];
  whitelistSaleStartTime: Scalars["Float"];
};

export type Sig = {
  __typename: "Sig";
  r: Scalars["String"];
  s: Scalars["String"];
  v: Scalars["Int"];
};

export type Whitelist = {
  __typename: "Whitelist";
  id: Scalars["ID"];
  whitelistStatus: WhitelistStatus;
  whitelistTimes: Scalars["Int"];
};

export enum WhitelistStatus {
  Active = "ACTIVE",
  Expired = "EXPIRED",
  Pending = "PENDING",
}

export type IsOnWhitelist = {
  __typename: "isOnWhitelist";
  isOnWhitelist: Scalars["Boolean"];
};

export type AttributesQueryVariables = Exact<{ [key: string]: never }>;

export type AttributesQuery = {
  __typename: "Query";
  attributes: Array<{
    __typename: "Attribute";
    label: string;
    content: string;
    count: number;
  }>;
};

export type NftsQueryVariables = Exact<{
  nftFilterInput: NftFilterInput;
}>;

export type NftsQuery = {
  __typename: "Query";
  nfts: {
    __typename: "NftConnection";
    edges?: Array<{
      __typename: "NftEdge";
      node: {
        __typename: "Nft";
        attributeClothing: string;
        attributeEyes: string;
        attributeEar: string;
        attributeFace: string;
        attributeMouth: string;
        attributeNeck: string;
        attributeSpecial: string;
        attributeHairFront: string;
        attributeHairBack: string;
        attributeDna: string;
        attributeSkinTone: string;
        id: string;
        isAnswerLocked: boolean;
        isAttributeSet: boolean;
        credit?: string | null;
        answer?: string | null;
        owner: string;
        question: string;
        questionCategory?: QuestionCategory | null;
        tokenId?: string | null;
        imageId: string;
        answerUpdateHistories?: Array<{
          __typename: "AnswerUpdateHistory";
          id: string;
          answer: string;
          ownerCredit: string;
          createdAt: any;
        }> | null;
      };
    }> | null;
    pageInfo?: {
      __typename: "PageInfo";
      startCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    } | null;
  };
};

export type SaleQueryVariables = Exact<{ [key: string]: never }>;

export type SaleQuery = {
  __typename: "Query";
  sale: {
    __typename: "Sale";
    whitelistSaleStartTime: number;
    whitelistSaleEndTime: number;
    publicSaleStartTime: number;
    whitelistSalePrice: string;
    publicSalePrice: string;
    revealStartTime: number;
    totalMintCount: number;
    stockCount: number;
  };
};

export type GetParamsForMintQueryVariables = Exact<{
  clientSig?: InputMaybe<Scalars["String"]>;
  count: Scalars["Int"];
  recaptchaToken: Scalars["String"];
}>;

export type GetParamsForMintQuery = {
  __typename: "Query";
  getParamsForMint: {
    __typename: "GetParamsForMint";
    isWhitelistSale: boolean;
    serverSig: { __typename: "Sig"; v: number; r: string; s: string };
  };
};

export type GetParamsForUpdateAnswerWithAttributeQueryVariables = Exact<{
  tokenId: Scalars["Int"];
}>;

export type GetParamsForUpdateAnswerWithAttributeQuery = {
  __typename: "Query";
  getParamsForUpdateAnswerWithAttribute: {
    __typename: "GetParamsForUpdateAnswerWithAttribute";
    attribute: {
      __typename: "NftAttribute";
      attributeClothing: string;
      attributeEyes: string;
      attributeEar: string;
      attributeFace: string;
      attributeHairFront: string;
      attributeHairBack: string;
      attributeDna: string;
      attributeMouth: string;
      attributeNeck: string;
      attributeSpecial: string;
      attributeSkinTone: string;
      question: string;
      questionCategory: QuestionCategory;
    };
    serverSig: { __typename: "Sig"; v: number; r: string; s: string };
  };
};

export type SecondAttributesQueryVariables = Exact<{ [key: string]: never }>;

export type SecondAttributesQuery = {
  __typename: "Query";
  secondAttributes: Array<{
    __typename: "SecondAttribute";
    label: string;
    content: string;
    count: number;
  }>;
};

export type SecondNftsQueryVariables = Exact<{
  secondNftFilterInput: SecondNftFilterInput;
}>;

export type SecondNftsQuery = {
  __typename: "Query";
  secondNfts: {
    __typename: "SecondNftConnection";
    edges?: Array<{
      __typename: "SecondNftEdge";
      node: {
        __typename: "SecondNft";
        attributeBackground: string;
        attributeCostume: string;
        attributeEar: string;
        attributeEye: string;
        attributeFace: string;
        attributeHairBack: string;
        attributeHairFront: string;
        attributeMouth: string;
        attributeNeck: string;
        attributeSkin: string;
        attributeSpecial: string;
        geneType: string;
        id: string;
        credit?: string | null;
        answer?: string | null;
        owner: string;
        question: string;
        questionCategory: string;
        tokenId?: string | null;
        imageId: string;
        secondAnswerUpdateHistories?: Array<{
          __typename: "SecondAnswerUpdateHistory";
          id: string;
          answer: string;
          ownerCredit: string;
          createdAt: any;
        }> | null;
      };
    }> | null;
  };
};

export type UpdateAnswerMutationVariables = Exact<{
  clientSig: Scalars["String"];
  answer: Scalars["String"];
  credit: Scalars["String"];
  tokenId: Scalars["Float"];
}>;

export type UpdateAnswerMutation = {
  __typename: "Mutation";
  updateAnswer: {
    __typename: "SecondNft";
    id: string;
    tokenId?: string | null;
    answer?: string | null;
    credit?: string | null;
  };
};

export type SecondSaleQueryVariables = Exact<{ [key: string]: never }>;

export type SecondSaleQuery = {
  __typename: "Query";
  secondSale: {
    __typename: "SecondSale";
    whitelistSaleStartTime: number;
    whitelistSaleEndTime: number;
    publicSaleStartTime: number;
    whitelistSalePrice: string;
    publicSalePrice: string;
    revealStartTime: number;
    totalMintCount: number;
    stockCount: number;
  };
};

export type GetParamsForSecondMintQueryVariables = Exact<{
  clientSig?: InputMaybe<Scalars["String"]>;
  count: Scalars["Int"];
  recaptchaToken: Scalars["String"];
}>;

export type GetParamsForSecondMintQuery = {
  __typename: "Query";
  getParamsForSecondMint: {
    __typename: "GetParamsForSecondMint";
    maxMintCount: number;
    mintedCount: number;
    proof: Array<string>;
  };
};

export const AttributesDocument = gql`
  query Attributes {
    attributes {
      label
      content
      count
    }
  }
`;

/**
 * __useAttributesQuery__
 *
 * To run a query within a React component, call `useAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttributesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAttributesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AttributesQuery,
    AttributesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AttributesQuery, AttributesQueryVariables>(
    AttributesDocument,
    options
  );
}
export function useAttributesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AttributesQuery,
    AttributesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AttributesQuery, AttributesQueryVariables>(
    AttributesDocument,
    options
  );
}
export type AttributesQueryHookResult = ReturnType<typeof useAttributesQuery>;
export type AttributesLazyQueryHookResult = ReturnType<
  typeof useAttributesLazyQuery
>;
export type AttributesQueryResult = Apollo.QueryResult<
  AttributesQuery,
  AttributesQueryVariables
>;
export const NftsDocument = gql`
  query Nfts($nftFilterInput: NftFilterInput!) {
    nfts(filter: $nftFilterInput) {
      edges {
        node {
          attributeClothing
          attributeEyes
          attributeEar
          attributeFace
          attributeMouth
          attributeNeck
          attributeSpecial
          attributeHairFront
          attributeHairBack
          attributeDna
          attributeSkinTone
          id
          isAnswerLocked
          isAttributeSet
          credit
          answer
          owner
          question
          questionCategory
          tokenId
          imageId
          answerUpdateHistories {
            id
            answer
            ownerCredit
            createdAt
          }
        }
      }
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

/**
 * __useNftsQuery__
 *
 * To run a query within a React component, call `useNftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNftsQuery({
 *   variables: {
 *      nftFilterInput: // value for 'nftFilterInput'
 *   },
 * });
 */
export function useNftsQuery(
  baseOptions: Apollo.QueryHookOptions<NftsQuery, NftsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NftsQuery, NftsQueryVariables>(NftsDocument, options);
}
export function useNftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NftsQuery, NftsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NftsQuery, NftsQueryVariables>(
    NftsDocument,
    options
  );
}
export type NftsQueryHookResult = ReturnType<typeof useNftsQuery>;
export type NftsLazyQueryHookResult = ReturnType<typeof useNftsLazyQuery>;
export type NftsQueryResult = Apollo.QueryResult<NftsQuery, NftsQueryVariables>;
export const SaleDocument = gql`
  query Sale {
    sale {
      whitelistSaleStartTime
      whitelistSaleEndTime
      publicSaleStartTime
      whitelistSalePrice
      publicSalePrice
      revealStartTime
      totalMintCount
      stockCount
    }
  }
`;

/**
 * __useSaleQuery__
 *
 * To run a query within a React component, call `useSaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useSaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSaleQuery({
 *   variables: {
 *   },
 * });
 */
export function useSaleQuery(
  baseOptions?: Apollo.QueryHookOptions<SaleQuery, SaleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SaleQuery, SaleQueryVariables>(SaleDocument, options);
}
export function useSaleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SaleQuery, SaleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SaleQuery, SaleQueryVariables>(
    SaleDocument,
    options
  );
}
export type SaleQueryHookResult = ReturnType<typeof useSaleQuery>;
export type SaleLazyQueryHookResult = ReturnType<typeof useSaleLazyQuery>;
export type SaleQueryResult = Apollo.QueryResult<SaleQuery, SaleQueryVariables>;
export const GetParamsForMintDocument = gql`
  query getParamsForMint(
    $clientSig: String
    $count: Int!
    $recaptchaToken: String!
  ) {
    getParamsForMint(
      clientSig: $clientSig
      count: $count
      recaptchaToken: $recaptchaToken
    ) {
      serverSig {
        v
        r
        s
      }
      isWhitelistSale
    }
  }
`;

/**
 * __useGetParamsForMintQuery__
 *
 * To run a query within a React component, call `useGetParamsForMintQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParamsForMintQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParamsForMintQuery({
 *   variables: {
 *      clientSig: // value for 'clientSig'
 *      count: // value for 'count'
 *      recaptchaToken: // value for 'recaptchaToken'
 *   },
 * });
 */
export function useGetParamsForMintQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParamsForMintQuery,
    GetParamsForMintQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetParamsForMintQuery, GetParamsForMintQueryVariables>(
    GetParamsForMintDocument,
    options
  );
}
export function useGetParamsForMintLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParamsForMintQuery,
    GetParamsForMintQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetParamsForMintQuery,
    GetParamsForMintQueryVariables
  >(GetParamsForMintDocument, options);
}
export type GetParamsForMintQueryHookResult = ReturnType<
  typeof useGetParamsForMintQuery
>;
export type GetParamsForMintLazyQueryHookResult = ReturnType<
  typeof useGetParamsForMintLazyQuery
>;
export type GetParamsForMintQueryResult = Apollo.QueryResult<
  GetParamsForMintQuery,
  GetParamsForMintQueryVariables
>;
export const GetParamsForUpdateAnswerWithAttributeDocument = gql`
  query getParamsForUpdateAnswerWithAttribute($tokenId: Int!) {
    getParamsForUpdateAnswerWithAttribute(tokenId: $tokenId) {
      attribute {
        attributeClothing
        attributeEyes
        attributeEar
        attributeFace
        attributeHairFront
        attributeHairBack
        attributeDna
        attributeMouth
        attributeNeck
        attributeSpecial
        attributeSkinTone
        question
        questionCategory
      }
      serverSig {
        v
        r
        s
      }
    }
  }
`;

/**
 * __useGetParamsForUpdateAnswerWithAttributeQuery__
 *
 * To run a query within a React component, call `useGetParamsForUpdateAnswerWithAttributeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParamsForUpdateAnswerWithAttributeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParamsForUpdateAnswerWithAttributeQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useGetParamsForUpdateAnswerWithAttributeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParamsForUpdateAnswerWithAttributeQuery,
    GetParamsForUpdateAnswerWithAttributeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetParamsForUpdateAnswerWithAttributeQuery,
    GetParamsForUpdateAnswerWithAttributeQueryVariables
  >(GetParamsForUpdateAnswerWithAttributeDocument, options);
}
export function useGetParamsForUpdateAnswerWithAttributeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParamsForUpdateAnswerWithAttributeQuery,
    GetParamsForUpdateAnswerWithAttributeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetParamsForUpdateAnswerWithAttributeQuery,
    GetParamsForUpdateAnswerWithAttributeQueryVariables
  >(GetParamsForUpdateAnswerWithAttributeDocument, options);
}
export type GetParamsForUpdateAnswerWithAttributeQueryHookResult = ReturnType<
  typeof useGetParamsForUpdateAnswerWithAttributeQuery
>;
export type GetParamsForUpdateAnswerWithAttributeLazyQueryHookResult =
  ReturnType<typeof useGetParamsForUpdateAnswerWithAttributeLazyQuery>;
export type GetParamsForUpdateAnswerWithAttributeQueryResult =
  Apollo.QueryResult<
    GetParamsForUpdateAnswerWithAttributeQuery,
    GetParamsForUpdateAnswerWithAttributeQueryVariables
  >;
export const SecondAttributesDocument = gql`
  query SecondAttributes {
    secondAttributes {
      label
      content
      count
    }
  }
`;

/**
 * __useSecondAttributesQuery__
 *
 * To run a query within a React component, call `useSecondAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecondAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecondAttributesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSecondAttributesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SecondAttributesQuery,
    SecondAttributesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SecondAttributesQuery, SecondAttributesQueryVariables>(
    SecondAttributesDocument,
    options
  );
}
export function useSecondAttributesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SecondAttributesQuery,
    SecondAttributesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SecondAttributesQuery,
    SecondAttributesQueryVariables
  >(SecondAttributesDocument, options);
}
export type SecondAttributesQueryHookResult = ReturnType<
  typeof useSecondAttributesQuery
>;
export type SecondAttributesLazyQueryHookResult = ReturnType<
  typeof useSecondAttributesLazyQuery
>;
export type SecondAttributesQueryResult = Apollo.QueryResult<
  SecondAttributesQuery,
  SecondAttributesQueryVariables
>;
export const SecondNftsDocument = gql`
  query SecondNfts($secondNftFilterInput: SecondNftFilterInput!) {
    secondNfts(filter: $secondNftFilterInput) {
      edges {
        node {
          attributeBackground
          attributeCostume
          attributeEar
          attributeEye
          attributeFace
          attributeHairBack
          attributeHairFront
          attributeMouth
          attributeNeck
          attributeSkin
          attributeSpecial
          geneType
          id
          credit
          answer
          owner
          question
          questionCategory
          tokenId
          imageId
          secondAnswerUpdateHistories {
            id
            answer
            ownerCredit
            createdAt
          }
        }
      }
    }
  }
`;

/**
 * __useSecondNftsQuery__
 *
 * To run a query within a React component, call `useSecondNftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecondNftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecondNftsQuery({
 *   variables: {
 *      secondNftFilterInput: // value for 'secondNftFilterInput'
 *   },
 * });
 */
export function useSecondNftsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SecondNftsQuery,
    SecondNftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SecondNftsQuery, SecondNftsQueryVariables>(
    SecondNftsDocument,
    options
  );
}
export function useSecondNftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SecondNftsQuery,
    SecondNftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SecondNftsQuery, SecondNftsQueryVariables>(
    SecondNftsDocument,
    options
  );
}
export type SecondNftsQueryHookResult = ReturnType<typeof useSecondNftsQuery>;
export type SecondNftsLazyQueryHookResult = ReturnType<
  typeof useSecondNftsLazyQuery
>;
export type SecondNftsQueryResult = Apollo.QueryResult<
  SecondNftsQuery,
  SecondNftsQueryVariables
>;
export const UpdateAnswerDocument = gql`
  mutation UpdateAnswer(
    $clientSig: String!
    $answer: String!
    $credit: String!
    $tokenId: Float!
  ) {
    updateAnswer(
      clientSig: $clientSig
      answer: $answer
      credit: $credit
      tokenId: $tokenId
    ) {
      id
      tokenId
      answer
      credit
    }
  }
`;
export type UpdateAnswerMutationFn = Apollo.MutationFunction<
  UpdateAnswerMutation,
  UpdateAnswerMutationVariables
>;

/**
 * __useUpdateAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnswerMutation, { data, loading, error }] = useUpdateAnswerMutation({
 *   variables: {
 *      clientSig: // value for 'clientSig'
 *      answer: // value for 'answer'
 *      credit: // value for 'credit'
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useUpdateAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAnswerMutation,
    UpdateAnswerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAnswerMutation,
    UpdateAnswerMutationVariables
  >(UpdateAnswerDocument, options);
}
export type UpdateAnswerMutationHookResult = ReturnType<
  typeof useUpdateAnswerMutation
>;
export type UpdateAnswerMutationResult =
  Apollo.MutationResult<UpdateAnswerMutation>;
export type UpdateAnswerMutationOptions = Apollo.BaseMutationOptions<
  UpdateAnswerMutation,
  UpdateAnswerMutationVariables
>;
export const SecondSaleDocument = gql`
  query SecondSale {
    secondSale {
      whitelistSaleStartTime
      whitelistSaleEndTime
      publicSaleStartTime
      whitelistSalePrice
      publicSalePrice
      revealStartTime
      totalMintCount
      stockCount
    }
  }
`;

/**
 * __useSecondSaleQuery__
 *
 * To run a query within a React component, call `useSecondSaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecondSaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecondSaleQuery({
 *   variables: {
 *   },
 * });
 */
export function useSecondSaleQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SecondSaleQuery,
    SecondSaleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SecondSaleQuery, SecondSaleQueryVariables>(
    SecondSaleDocument,
    options
  );
}
export function useSecondSaleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SecondSaleQuery,
    SecondSaleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SecondSaleQuery, SecondSaleQueryVariables>(
    SecondSaleDocument,
    options
  );
}
export type SecondSaleQueryHookResult = ReturnType<typeof useSecondSaleQuery>;
export type SecondSaleLazyQueryHookResult = ReturnType<
  typeof useSecondSaleLazyQuery
>;
export type SecondSaleQueryResult = Apollo.QueryResult<
  SecondSaleQuery,
  SecondSaleQueryVariables
>;
export const GetParamsForSecondMintDocument = gql`
  query GetParamsForSecondMint(
    $clientSig: String
    $count: Int!
    $recaptchaToken: String!
  ) {
    getParamsForSecondMint(
      clientSig: $clientSig
      count: $count
      recaptchaToken: $recaptchaToken
    ) {
      maxMintCount
      mintedCount
      proof
    }
  }
`;

/**
 * __useGetParamsForSecondMintQuery__
 *
 * To run a query within a React component, call `useGetParamsForSecondMintQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParamsForSecondMintQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParamsForSecondMintQuery({
 *   variables: {
 *      clientSig: // value for 'clientSig'
 *      count: // value for 'count'
 *      recaptchaToken: // value for 'recaptchaToken'
 *   },
 * });
 */
export function useGetParamsForSecondMintQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParamsForSecondMintQuery,
    GetParamsForSecondMintQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetParamsForSecondMintQuery,
    GetParamsForSecondMintQueryVariables
  >(GetParamsForSecondMintDocument, options);
}
export function useGetParamsForSecondMintLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParamsForSecondMintQuery,
    GetParamsForSecondMintQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetParamsForSecondMintQuery,
    GetParamsForSecondMintQueryVariables
  >(GetParamsForSecondMintDocument, options);
}
export type GetParamsForSecondMintQueryHookResult = ReturnType<
  typeof useGetParamsForSecondMintQuery
>;
export type GetParamsForSecondMintLazyQueryHookResult = ReturnType<
  typeof useGetParamsForSecondMintLazyQuery
>;
export type GetParamsForSecondMintQueryResult = Apollo.QueryResult<
  GetParamsForSecondMintQuery,
  GetParamsForSecondMintQueryVariables
>;
