import CloseSvg from "../CloseSvg";
import styles from "./index.module.scss";

const CloseBtn = ({
  onClick,
  extraClass,
}: {
  onClick: () => void;
  extraClass?: string;
}) => (
  <button
    type="button"
    className={`${styles["button"]} ${extraClass ? extraClass : ""}`}
    onClick={onClick}
  >
    <CloseSvg />
  </button>
);

export default CloseBtn;
