const PlusSvg = ({ fill = "#fff" }: { fill?: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 11.1111H20V8.88889H0V11.1111Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1111 20V0H8.88889V20H11.1111Z"
    />
  </svg>
);

export default PlusSvg;
