const CloseSvg = ({ size = "20" }: { size?: string }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 21"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.81816 20.2544L19.9998 2.0728L18.1816 0.25464L0 18.4362L1.81816 20.2544Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1816 20.2541L3.04986e-05 2.07255L1.81819 0.254395L19.9998 18.436L18.1816 20.2541Z"
    />
  </svg>
);

export default CloseSvg;
