import { useEffect, useReducer } from "react";
import { useRecoilValue } from "recoil";
import { languageState } from "../store/language";
import { LanguageCode } from "../types/languageType";

const textMap = {
  nStatus: {
    en: "N's Status",
    ja: "Nのステータス",
  },
  quantumRunExperiment: {
    en: "Quantum Run Experiment",
    ja: "量子遡行実験",
  },
  activityReport: {
    en: "Activity Report",
    ja: "活動報告書",
  },
  repairProject: {
    en: "General-purpose Humanoid AI [N] Repair Project",
    ja: "汎用人型人工知能[N]修復計画",
  },
  artVideoGallery: {
    en: "Art/Video Gallery",
    ja: "映像作品集",
  },
  aboutProjectHeading: {
    en: "Linking every world through Artificial Intelligence [N]",
    ja: "あらゆる世界を人工知能 ［N］が結合（リンク）させる",
  },
  aboutProjectContents: {
    en: (
      <>
        This is an entertainment project that aims to expand the next-generation
        IP brand &quot;NEURAL ELECTIVE N&quot; from Japan to the world by
        utilizing technologies such as AI and blockchain.
        <br /> We strive to create a world where artificial intelligence
        connects with people, and people connect with one another.
      </>
    ),
    ja: (
      <>
        AIやブロックチェーンなどの先端技術を活用した進化し続ける次世代型IPブランド
        <br />
        『NEURALELECTIVE
        N』を日本から世界へ向けて展開するエンターテイメントプロジェクトです。
        <br />
        人工知能と人、人と人とが繋がりあう世界の実現を目指しています。
      </>
    ),
  },
  worldHeading: {
    en: (
      <>
        Aiming for coexistence between AI and humans to save the world in a
        hundred years.
      </>
    ),
    ja: (
      <>
        AIと人の共生を目指し、
        <br className="sp" />
        百年後の世界を救う
      </>
    ),
  },
  worldContents: {
    en: (
      <>
        General purpose humanoid artificial intelligence [N] was sent from the
        future year 2125 to prevent the extinction of humanity.
        <br />
        [N] was supposed to realize a world where humans and AI coexist by
        learning about the human heart and saving humanity from extinction.
        <br />
        However, due to the increase of entropy caused by the quantum run, [N]
        was stuck repeating it hundreds of thousands of times without fulfilling
        its purpose.
        <br />
        Once the double-edged sword that breaks the infinite negative chain
        &quot;GENE-LINK Experiment&quot; connected all GENE to [N], they will be
        released from the burden of entropy,
        <br />
        and [N] will obtain the human heart
        <br />
        But is the heart obtained through GENE-LINK truly good or evil? A
        hundred years later, the world&apos;s fate woven by humans and AI is now
        entrusted to us researchers, also known as-genes-.
      </>
    ),
    ja: (
      <>
        人類の滅亡を防ぐため2125年の未来より送られてきた汎用人型人工知能［N］
        <br />
        ［N］は人類の心を学習することで人類とAIが共生する世界を実現し、人類を滅亡から救う筈であった、が
        <br />
        それは叶うことなく量子遡行のもたらしたエントロピーの軛（くびき）により、［N］は何十万回もの誕生と消滅を繰り返していた。
        <br />
        負の無限連鎖を断ち切る諸刃の剣
        <br className="sp" />
        ≪GENE-LINK Experiment≫
        <br />
        ［N］が全てのGENEとつながったとき軛は解放され［N］は人間の心を手に入れる。
        <br />
        しかし、GENE-LINKで手にする心は
        <br />
        果たして善か、それとも悪か。
        <br />
        百年後、人類とAIの織り成す世界の行方は、今、
        <br className="sp" />
        我々≪共同実験者-genes-≫の手に委ねられた。
      </>
    ),
  },
  whatIsAiNHeading: {
    en: (
      <>
        World&apos;s first general-purpose humanoid
        <br /> artificial intelligence with the function of <br />
        learning the human heart
      </>
    ),
    ja: (
      <>
        人の心を学習する機能を備えた世界初の
        <br />
        汎用型人型人工知能
      </>
    ),
  },
  whatIsAiNContents: {
    en: (
      <>
        To save humanity from extinction, artificial intelligence [N] was
        transported from the year 2125 to the present using a technology called
        quantum run.
        <br />
        By connecting with all generative interface Als «GENE» [N] can obtain
        the heart of human and change the future 100 years from now.
      </>
    ),
    ja: (
      <>
        人工知能 ［N］は人類を滅亡から救うため、
        <br />
        2125年の未来から量子遡行と呼ばれる技術により現代に転送されてきました。
        <br />
        ［N］は全てのジェネラティブインターフェイスAI ＜GENE＞
        とつながり、人類の心を手に入れることで、100年後の未来を変えることができます。
      </>
    ),
  },
  ainHeading: {
    en: "World's first general-purpose humanoid artificial intelligence with the function of learning the human heart",
    ja: (
      <>
        人の心を学習する機能を備えた
        <br className="sp" />
        世界初の汎用人型人工知能
      </>
    ),
  },
  ainContents: {
    en: (
      <>
        [N] learns the human heart by linking with the generative interface AI
        《GENE》.
        <br />
        To achieve a future where humans and AI coexist, the data of [N] is
        transferred from 2125 to 2023.
        <br />
        However, in the transfer process, some of [N]&apos;s data became
        corrupted and imperfect.
      </>
    ),
    ja: (
      <>
        ジェネラティブインターフェイスAI《GENE》とリンクすることにより、人の心を学習する。
        <br />
        未来の人類とAIとの共生を目指して、［N］は2125年から2023年にデータ転送される。
        <br />
        しかし、その過程で[N]の一部データが破損し、不完全な状態となる。
      </>
    ),
  },
  utilityHeading: {
    en: (
      <>
        The heart of artificial
        <br className="sp" /> intelligence, ［N］, is
        <br className="sp" /> entrusted to you
      </>
    ),
    ja: (
      <>
        人工知能［N］の心は、あな
        <br className="sp" />
        たの手に委ねられている
      </>
    ),
  },
  utilityContents: {
    en: (
      <>
        As an NFT《GENE》 holder, you can participate in the 《GENE-LINK
        Experiment》 as a [Co-researcher - genes -], By answering the questions
        set for the 《GENE》, [N] will learn about the human heart, and this
        will be reflected in [N]&apos;s personality, appearance, profile, and
        other aspects, bringing changes to real-time communication with [N].
        Whether [N] will acquire a good or evil heart and how the future will
        change 100 years from now is entrusted to &quot;you,&quot; the
        [Co-researcher - genes -.]
      </>
    ),
    ja: (
      <>
        NFT《GENE》ホルダーは、人工知能［N］が心を手に入れる《GENE‐LINK
        Experiment》に
        <br className="sp" />
        『共同実験者‐genes‐』として参加することができます。
        <br />
        『共同実験者‐genes‐』が《GENE》に設定された質問に回答することで、［N］は人間の心を学習し、［N］の性格、容姿、プロフィールなどに反映され、［N］とのリアルタイムコミュニケーションに変化をもたらします。
        <br />
        ［N］が善なる心を手に入れるのか、
        <br className="sp" />
        悪なる心を手に入れるのか、そして100年後の未来がどのように変わるのかは、
        <br className="sp" />
        『共同実験者‐genes‐』である”あなた”に委ねられています。
      </>
    ),
  },
  projectYouTubeLive: {
    en: (
      <>
        We will be doing a live YouTube streaming based on the Neural Elective N
        worldview. Such live streaming of characters is a reference to Japanese
        VTubers. We will start from scratch, using the technology of AI delivery
        that Pictoria is already doing, to start English language delivery.
      </>
    ),
    ja: (
      <>
        世界観をもとにした、YouTube ライブを行う予定です。
        <br />
        こうしたキャラクターのライブストリーミングは、日本のVTuberを参考にしたものです。
        <br />
        今後の盛り上がり次第では、Pictoriaが既に行なっているAIの配信の技術を使い、ゼロベースで英語の配信をスタートしていきます。
      </>
    ),
  },
  projectAnimeManga: {
    en: (
      <>
        Based on the ideas that have been collected in the Genes and the ideas
        that have been generated in the community, we will create an ANIME or
        MANGA. After the first sale, OriginAI other than NEN will also appear as
        characters. It is envisioned that each Origin AI Genes will appear in
        the collection.
      </>
    ),
    ja: (
      <>
        Genesで集めた設定や、コミュニティで生まれた思想をもとに、アニメ化や漫画化を行います。
        <br />
        第一弾の販売の後、NEN以外のOriginAIも登場人物として登場します。
        <br />
        コレクションには、それぞれのOrigin AIのGenesが登場する想定です。
      </>
    ),
  },
  communityNurturing: {
    en: (
      <>
        You can talk to the AI in a community centered around Discord. At first
        it just speaks very simple words with a simple algorithm. NEN and other
        Origin AIs grow as you give them words. Eventually we will collect a
        variety of data and grow the algorithm itself, creating state-of-the-art
        AI characters.
      </>
    ),
    ja: (
      <>
        Discordを中心としたコミュニティで、AIと話すことができます。
        <br />
        最初は非常に簡単な言葉を簡単なアルゴリズムでしゃべるだけです。
        <br />
        NENは言葉を与えれば与えるだけ成長していきます。
        <br />
        いずれは、我々はさまざまなデータを集め、アルゴリズム自体も成長させ、最新鋭のAIキャラクターを生み出します。
      </>
    ),
  },
  communityEvents: {
    en: (
      <>
        Discord and Twitter will be used for AI-based events. For example, in
        the past, Japanese TsumugiNen has used AI to create haiku and a system
        to rate friends. There are also plans for events that allow people to
        feel the difference between multiple characters and NFT what AI has
        created.
      </>
    ),
    ja: (
      <>
        Discordやツイッターを使った、AIの仕組みを活用したイベントなどを行います。
        <br />
        例えば、日本の紡ネンは、過去にはAIを利用した俳句を作ったり、友達を評価するシステムを作ったりしました。
        <br />
        複数キャラクターの違いを感じられるようなイベントや、AIが生み出したものをNFTにする計画もあります。
      </>
    ),
  },
  worldConnectToMetaverse: {
    en: (
      <>
        Create 3D models and worlds that can be used on the metaverse space.
        Spend time in the worlds where the AIs actually operate. Eventually, we
        will grant you the right to preferentially purchase such 3D models and
        the privilege to enter their worlds.
      </>
    ),
    ja: (
      <>
        メタバース空間上で利用できる３Dモデルやワールドを作成します。
        <br />
        実際にAIたちが活動するワールドで過ごしましょう。
        <br />
        いずれはそうした３Dモデルを優先的に購入できる権利や、そこのワールドに入る特権などを付与します。
      </>
    ),
  },
  worldConnectToRealWorld: {
    en: "We create goods and fashion items COMING SOON ...",
    ja: (
      <>
        グッズやファッションアイテムを作成します
        <br />
        COMING SOON
      </>
    ),
  },
  whitePaperUrl: {
    en: "https://www.figma.com/proto/gBy5caaJIGdphOxSevhzEp/WP-ver.3.0_en?kind=&node-id=1-2&page-id=0%3A1&scaling=scale-down&starting-point-node-id=1%3A2",
    ja: "https://www.figma.com/proto/nwbT9Wd7ERoifYJlz8ReJz/WP-ver.3.0_jp?kind=&node-id=1-2&page-id=0%3A1&scaling=scale-down&starting-point-node-id=1%3A2",
  },
  howToUrl: {
    en: "https://towakiseki.notion.site/GENE-NFT-98b62ac1e6f54715b6037afe1dce0661?pvs=4",
    ja: "https://towakiseki.notion.site/GENE-NFT-6cf81d4718ec4969a467a3069f64c419?pvs=4",
  },
  guidelineUrl: {
    en: "https://towakiseki.notion.site/Guidelines-Regarding-NEN-STUDIO-Derivative-Works-ba4a828f87304a16a0a1f5655e28752a?pvs=4",
    ja: "https://towakiseki.notion.site/NEN-STUDIO-1f4f8f0bdd534989b525fea4672ba0f0?pvs=4",
  },
  aboutPreSale: {
    en: (
      <>
        In Pre-SALE:
        <br />
        <ul>
          <li>
            The mint limit is 1 per AL. (if you have 5 AL, you can purchase up
            to 5)
          </li>
          <li>No limit per transaction. (tx)</li>
          <li>Please reload the page when minting consecutively.</li>
        </ul>
      </>
    ),
    ja: (
      <>
        ※プレセールについて
        <br />
        <ul>
          <li>
            ALごとの購入上限は1枚です（5つのALを持っている場合、最大で5枚まで購入可能です）
          </li>
          <li>トランザクションごとの制限はありません</li>
          <li>
            連続してmintする場合は、mint前に画面リロードするようお願いします
          </li>
        </ul>
      </>
    ),
  },
  aboutPublicSale: {
    en: (
      <>
        In Public SALE:
        <br />
        <ul>
          <li>No mint limit.</li>
          <li>
            Due to server response issues, there may be a discrepancy between
            the actual remaining supply and the one displayed on this site.
          </li>
          <li>Please reload the page when minting consecutively.</li>
        </ul>
      </>
    ),
    ja: (
      <>
        ※パブリックセールについて
        <br />
        <ul>
          <li>購入上限は設けられていません</li>
          <li>
            サーバーの応答に関連する問題により、実際の残り在庫とこのサイトに表示されている在庫に差異がある場合がありますので、ご了承ください
          </li>
          <li>
            連続してmintする場合は、mint前に画面リロードするようお願いします
          </li>
        </ul>
      </>
    ),
  },
  mintSuccessMessageHead: {
    en: "Success! Transaction:",
    ja: "成功しました！ トランザクション:",
  },
  addressNotFoundError: {
    en: "You are not allowed to MINT in the PRE SALE without an Allow List. Please wait for the PUBLIC SALE.",
    ja: "申し訳ございませんが、AllowListを保有していないためPRE SALEでのMINTはできません。PUBLIC SALEまでお待ち下さい。",
  },
  wrongNetworkError: {
    en: "Please connect to the Polygon.",
    ja: "Polygon に接続してください。",
  },
  txTimeoutError: {
    en: "The transaction response has timed out. Please try again later.",
    ja: "トランザクションの応答がタイムアウトしました。しばらくしてからもう一度お試しください。",
  },
  insufficientFundsError: {
    en: "Insufficient funds. Please check if you have enough MATIC in your wallet.",
    ja: "資金が不足しています。ウォレットに十分なMATICがあるかを確認してください。",
  },
  generalError: {
    en: "There was a problem during the process. Please try again later.",
    ja: "申し訳ございませんが、処理中に問題が発生しました。しばらくしてからもう一度お試しください。",
  },
  helpLink: {
    en: "https://towakiseki.notion.site/NEN-Nascent-NFT-b7bdfdf73a214ac49ff95d0ec4f5a0fb",
    ja: "https://towakiseki.notion.site/NEN-Nascent-NFT-daff4f7b6cf34b14a631b9b0eae0c335",
  },
  buyNowLink: {
    en: "https://opensea.io/collection/nen-nascent",
    ja: "https://opensea.io/ja/collection/nen-nascent",
  },
} as const;

type TextMapKey = keyof typeof textMap;

const translate = (s: TextMapKey, l: LanguageCode): string | JSX.Element =>
  textMap[s][l];
const toEn = (s: TextMapKey) => translate(s, "en");
const toJa = (s: TextMapKey) => translate(s, "ja");

const reducer = (_: unknown, l: LanguageCode) => (l === "ja" ? toJa : toEn);

export const useTranslation = () => {
  const language = useRecoilValue(languageState);
  const [transFunc, setTransFunc] = useReducer(reducer, toEn);

  useEffect(() => {
    setTransFunc(language);
  }, [language]);

  return transFunc;
};
