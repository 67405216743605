import { ApolloProvider } from "@apollo/react-hooks";
import { Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from "@web3-react/core";
import type { AppContext, AppProps } from "next/app";
import App from "next/app";
import { useEffect } from "react";
import { RecoilRoot, useSetRecoilState } from "recoil";
import "swiper/css/bundle";
import "tailwindcss/tailwind.css";
import Layout from "../components/common/layouts/Layout";
import basicAuthCheck from "../libs/basicAuthCheck";
import { genGqlClient } from "../libs/gqlClient";
import { saleState } from "../store/sale";
import "../styles/globals.scss";
import { Sale } from "../types/saleType";

import { useSecondSaleLazyQuery } from "../libs/schema";

import { usePageView } from "../hooks/usePageView";

const getLibrary = (provider: any) => new Web3Provider(provider);

function AppInit() {
  // グローバルステートに Nascent の SALE 情報をセットする
  const setSale = useSetRecoilState(saleState);

  // Mint Sale情報取得
  const [
    fetchSale,
    {
      /* loading, error, data */
    },
  ] = useSecondSaleLazyQuery();

  // Mint Sale情報を取得する
  const doFetchSale = async () => {
    const { data } = await fetchSale();
    const res = data?.secondSale;

    if (!res) {
      setSale({
        stockCount: 0,
        publicSalePrice: "",
        publicSaleStartTime: 0,
        revealStartTime: 0,
        totalMintCount: 0,
        whitelistSaleEndTime: 0,
        whitelistSalePrice: "",
        whitelistSaleStartTime: 0,
      });
      return;
    }

    const saleData: Sale = {
      stockCount: res.stockCount,
      publicSalePrice: res.publicSalePrice,
      publicSaleStartTime: res.publicSaleStartTime,
      revealStartTime: res.revealStartTime,
      totalMintCount: res.totalMintCount,
      whitelistSaleEndTime: res.whitelistSaleEndTime,
      whitelistSalePrice: res.whitelistSalePrice,
      whitelistSaleStartTime: res.whitelistSaleStartTime,
    };

    setSale(saleData);
  };

  useEffect(() => {
    doFetchSale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

function MyApp({ Component, pageProps }: AppProps) {
  usePageView();
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <ApolloProvider client={genGqlClient()}>
        <RecoilRoot>
          <Layout>
            <Component {...pageProps} />
            <AppInit />
          </Layout>
        </RecoilRoot>
      </ApolloProvider>
    </Web3ReactProvider>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  if (process.env.BASIC_AUTHZ !== "true") {
    const appProps = await App.getInitialProps(appContext);
    return { ...appProps };
  }

  const { req, res } = appContext.ctx;
  if (req && res) {
    basicAuthCheck(req, res);
  }

  const appProps = await App.getInitialProps(appContext);
  return { ...appProps };
};

export default MyApp;
