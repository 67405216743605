import Head from "next/head";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useState } from "react";
import Footer from "../Footer/footer";
import { FooterFixed } from "../Footer/footerFixed";
import Header from "../Header/header";
import Loading from "../Loading";

type Props = {
  children?: ReactNode;
};

const Layout = ({ children }: Props) => {
  const router = useRouter();
  const [isFooterFixedVisible, setIsFooterFixedVisible] = useState(true);
  const handleFooterFixedCancel = (isVisible = false) => {
    setIsFooterFixedVisible(isVisible);
  };
  useEffect(() => {
    handleFooterFixedCancel(true);
  }, [router.pathname]);

  return (
    <div>
      <Head>
        <title>MyTemplate</title>
      </Head>
      <div className="min-h-full min-w-full">
        <Header />
        <Loading />
        <main className="text-white">{children}</main>
        <div className="fixed top-0 w-screen h-screen bg-[url('/images/noise.gif')] opacity-10 -z-[1]" />
        <Footer isFooterFixed={isFooterFixedVisible} />
        <FooterFixed
          onCancel={() => {
            handleFooterFixedCancel();
          }}
        />
      </div>
    </div>
  );
};

export default Layout;
