import { News, NewsIds, NewsListData, NewsQueries } from "../types/newsType";

const buildApiUrl = (path: string, queries?: NewsQueries): string | null => {
  const API_ENDPOINT = process.env.MICROCMS_API_ENDPOINT || "";

  if (!API_ENDPOINT) return null;

  const url = API_ENDPOINT + path;

  if (!queries) return url;

  const params = new URLSearchParams(queries);

  return `${url}?${params.toString()}`;
};

const fetchMicroCmsData = async <T>(
  path: string,
  queries?: NewsQueries
): Promise<T | null> => {
  const API_KEY = process.env.MICROCMS_API_KEY || "";
  const url = buildApiUrl(path, queries);

  if (!url) return null;

  const res = await fetch(url, {
    headers: {
      "X-MICROCMS-API-KEY": API_KEY,
    },
  });

  return res.ok ? res.json() : null;
};

const NEWS_PATH = "/news";

const ID_KEY = "id";
const NEWS_FIELDS_FOR_LIST_STR = ID_KEY + ",publishedAt,title,eyecatch";
const ALL_NEWS_FIELDS_STR = NEWS_FIELDS_FOR_LIST_STR + ",content";

export const fetchNewsList = async ({
  offset,
  limit,
}: {
  offset?: number;
  limit?: number;
}): Promise<NewsListData | null> => {
  const queries: NewsQueries = {
    fields: NEWS_FIELDS_FOR_LIST_STR,
  };
  if (offset !== undefined) queries.offset = offset;
  if (limit !== undefined) queries.limit = limit;

  return fetchMicroCmsData<NewsListData>(NEWS_PATH, queries);
};

const MAX_NEWS_NUM = 100;
export const fetchNewsIds = async (): Promise<NewsIds | null> => {
  return fetchMicroCmsData<NewsIds>(NEWS_PATH, {
    limit: MAX_NEWS_NUM,
    fields: ID_KEY,
  });
};

export const fetchOneNews = async (id: string): Promise<News | null> => {
  return fetchMicroCmsData<News>(`${NEWS_PATH}/${id}`, {
    fields: ALL_NEWS_FIELDS_STR,
  });
};

export const buildNewsListPath = (i: number): string => `/news/page/${i + 1}`;

const DEFAULT_NEWS_IMG_PATH = "/images/products/default-news-eyecatch.png";
export const buildNewsSrc = (n: Pick<News, "eyecatch">): string =>
  n.eyecatch ? n.eyecatch.url : DEFAULT_NEWS_IMG_PATH;
