import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { useMedia } from "react-use";
import { MOBILE_MAX_WIDTH } from "../../../constant";
import { useTranslation } from "../../../services/i18n";
import { buildNewsListPath } from "../../../services/news";
import { Accordion } from "../Accordion";
import RightArrow from "../ui/RIghtArrow";
import styles from "./header.module.scss";

type Props = {
  // リビール開始か？
  isOpenReveal: boolean;
};

const HeaderMenu = ({ isOpenReveal }: Props) => {
  const router = useRouter();
  const isMobile = useMedia(`(max-width: ${MOBILE_MAX_WIDTH}px)`, false);

  return isMobile ? (
    <SPMenu path={router.asPath} />
  ) : (
    <PCMenu path={router.asPath} />
  );
};

const PCMenu = ({ path }: { path: string }) => {
  const [isHomeOpen, setIsHomeOpen] = useState(false);
  const [isContentsOpen, setIsContentsOpen] = useState(false);
  const nodeHome: any = useRef();
  const nodeContents: any = useRef();
  const t = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (nodeHome.current && !nodeHome.current.contains(event.target)) {
        setIsHomeOpen(false);
      } else {
        if (isHomeOpen && path === "/") {
          setIsHomeOpen(false);
        } else {
          setIsHomeOpen(true);
        }
      }
      if (
        nodeContents.current &&
        !nodeContents.current.contains(event.target)
      ) {
        setIsContentsOpen(false);
      } else {
        if (isContentsOpen && path === "/contents") {
          setIsContentsOpen(false);
        } else {
          setIsContentsOpen(true);
        }
      }
    };

    window.addEventListener("click", handleClickOutside);
    // クリーンアップ関数
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isHomeOpen, isContentsOpen, path]);

  return (
    <nav className={styles["nav-list"]}>
      <div
        className={`${styles["nav-list-1st-child"]} ${styles["is-accordion"]} ${
          isHomeOpen ? styles["is-open"] : ""
        } ${
          path === "/" || path.startsWith("/?") || path.startsWith("/#")
            ? styles["is-active"]
            : ""
        }`}
        ref={nodeHome}
      >
        <HomeAccordion isOpen={isHomeOpen} path={path} />
      </div>
      <div className={styles["nav-list-1st-child"]}>
        <Link href={buildNewsListPath(0)} passHref>
          <a
            className={`${styles["nav-anchor"]} ${
              path.startsWith("/news") && styles["active"]
            }`}
          >
            NEWS
          </a>
        </Link>
      </div>
      <div
        className={`${styles["nav-list-1st-child"]} ${styles["is-accordion"]} ${
          isContentsOpen ? styles["is-open"] : ""
        } ${path.startsWith("/contents") ? styles["is-active"] : ""}`}
        ref={nodeContents}
      >
        <ContentAccordion isOpen={isContentsOpen} path={path} />
      </div>
      <div className={styles["nav-list-1st-child"]}>
        <Link href={"/gallery"} passHref>
          <a
            className={`${styles["nav-anchor"]}
                   ${path == "/gallery" && styles["active"]}`}
          >
            GALLERY
          </a>
        </Link>
      </div>
      <div className={styles["nav-list-1st-child"]}>
        <a
          className={styles["nav-anchor"]}
          href={t("helpLink") as string}
          target="_blank"
          rel="noopener noreferrer"
        >
          HELP
        </a>
      </div>
    </nav>
  );
};

const SPMenu = ({ path }: { path: string }) => {
  const [isHomeOpen, setIsHomeOpen] = useState(false);
  const [isContentsOpen, setIsContentsOpen] = useState(false);
  const t = useTranslation();

  return (
    <nav className="flex flex-col">
      <div onClick={() => setIsHomeOpen((prev) => !prev)}>
        <HomeAccordion isOpen={isHomeOpen} path={path} />
      </div>
      <SPMenuLink href={buildNewsListPath(0)} text="NEWS" />
      <div onClick={() => setIsContentsOpen((prev) => !prev)}>
        <ContentAccordion isOpen={isContentsOpen} path={path} />
      </div>
      <SPMenuLink href="/gallery" text="GALLERY" />
      <a
        className={styles["sp-nav-link"]}
        href={t("helpLink") as string}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div>HELP</div>
        <RightArrow />
      </a>
    </nav>
  );
};

const SPMenuLink = ({ href, text }: { href: string; text: string }) => (
  <Link href={href} passHref>
    <a className={styles["sp-nav-link"]}>
      <div>{text}</div>
      <RightArrow />
    </a>
  </Link>
);

const HomeAccordion = ({ isOpen, path }: { isOpen: boolean; path: string }) => (
  <Accordion
    title="HOME"
    titleHref="/"
    isOpen={isOpen}
    isActive={path === "/"}
    contents={[
      { href: "/?about#about", text: "ABOUT PROJECT" },
      { href: "/?world#about", text: "WORLD" },
      { href: "/?what#about", text: "WHAT IS AI [N] ?" },
      { href: "/#utility", text: "NFT UTILITY" },
    ]}
  />
);

const ContentAccordion = ({
  isOpen,
  path,
}: {
  isOpen: boolean;
  path: string;
}) => {
  const t = useTranslation();

  return (
    <Accordion
      title="CONTENT"
      titleHref="/contents"
      isOpen={isOpen}
      isActive={path === "/contents"}
      contents={[
        { href: "/contents#contents1", text: t("nStatus") as string },
        {
          href: "/contents#contents2",
          text: t("quantumRunExperiment") as string,
        },
        { href: "/contents#contents3", text: t("activityReport") as string },
        {
          href: "/contents#contents4",
          text: t("repairProject") as string,
        },
        { href: "/contents#contents5", text: t("artVideoGallery") as string },
      ]}
    />
  );
};

export default HeaderMenu;
