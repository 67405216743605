import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { LanguageCode } from "../types/languageType";

const isServerSide = typeof window === "undefined";

// ref: https://qiita.com/dende-h/items/16d8d65d52361000cef7
const { persistAtom } = recoilPersist({
  key: "persist",
  storage: isServerSide ? undefined : localStorage,
});

const getDefaultLanguage = (): LanguageCode => {
  if (isServerSide) return "en";
  return navigator.language === "ja" ? "ja" : "en";
};

export const languageState = atom<LanguageCode>({
  key: "language",
  default: getDefaultLanguage(),
  effects_UNSTABLE: [persistAtom],
});
