import Image from "next/image";
import { useEffect, useReducer } from "react";
import { Transition } from "react-transition-group";
import { useMedia } from "react-use";
import { MOBILE_MAX_WIDTH } from "../../../constant";
import styles from "./index.module.scss";

const LOADING_SEC = 1.5;
const TRANSITION_TIMEOUT_MS = LOADING_SEC * 1000 * 1.1;
const sleep = (sec: number) =>
  new Promise((resolve) => setTimeout(resolve, sec * 1000));

const Loading = () => {
  const isMobile = useMedia(`(max-width: ${MOBILE_MAX_WIDTH}px)`, false);

  const [isLoaded, updateIsLoaded] = useReducer(() => true, false);

  useEffect(() => {
    (async () => {
      await sleep(LOADING_SEC);
      updateIsLoaded();
    })();
  }, []);

  return (
    <Transition in={!isLoaded} timeout={TRANSITION_TIMEOUT_MS} unmountOnExit>
      {(state) => (
        <div
          className={styles.loadingPage}
          style={{
            transition: "0.3s ease-in-out",
            ...(
              {
                entering: { opacity: 1 },
                entered: { opacity: 1 },
                exiting: { opacity: 0 },
                exited: { opacity: 0 },
              } as any
            )[state],
          }}
        >
          <BackgroundFrame isMobile={isMobile} />
          <Center isMobile={isMobile} />
        </div>
      )}
    </Transition>
  );
};

const Center = ({ isMobile }: { isMobile: boolean }) => (
  <div>
    <div className={styles.logoWrapper}>
      <Image
        src="/images/loading/logo.svg"
        width={isMobile ? 160 : 345.24}
        height={isMobile ? 94.35 : 203.59}
        alt=""
      />
    </div>
    <LoadingCircle />
    <div className={styles.loadingTextWrapper}>
      <Image
        src="/images/loading/loading-text.svg"
        width={isMobile ? 99 : 197}
        height={isMobile ? 24 : 48}
        alt=""
      />
    </div>
  </div>
);

const LoadingCircle = () => (
  <div className={styles.loadingCircle}>
    <Rect />
    <Rect />
    <Rect />
    <Rect />
    <Rect />
    <Rect />
    <Rect />
    <Rect />
  </div>
);

const Rect = () => <div className={styles.rect}></div>;

const BackgroundFrame = ({ isMobile }: { isMobile: boolean }) => (
  <div className="fixed inset-0">
    {isMobile ? (
      <>
        <SpBackgroundUpper />
        <SpBackgroundBottom />
        <SpBackgroundLeft />
      </>
    ) : (
      <>
        <BackgroundLeft />
      </>
    )}
    <BackgroundRight isMobile={isMobile} />
  </div>
);

const BackgroundLeft = () => (
  <div className="fixed left-0 w-[50vw] h-screen overflow-hidden">
    <div className="absolute left-[48.2px] top-1/2 -translate-y-1/2 w-[35.07px] h-[892px] max-h-[84vh]">
      <Image
        src="/images/loading/left.png"
        layout="fill"
        objectFit="contain"
        alt=""
      />
    </div>
    <BackgroundUpperLeft />
    <BackgroundBottomLeft />
  </div>
);

const SpBackgroundLeft = () => (
  <div className="w-screen h-screen overflow-x-hidden">
    <div className="absolute left-[16px] top-1/2 -translate-y-1/2">
      <Image
        src="/images/loading/sp-left-line.png"
        width={25}
        height={568.78}
        alt=""
      />
    </div>
    <SmallCircles isMobile={true} />
    <SmallUpperLeftText isMobile={true} />
    <SmallBottomLeftText isMobile={true} />
    <SmallBottomText isMobile={true} />
  </div>
);

const BackgroundRight = ({ isMobile }: { isMobile: boolean }) => (
  <div className="fixed top-0 right-0 w-[50vw] h-screen overflow-hidden">
    {isMobile ? (
      <>
        <div className="absolute right-[16px] top-1/2 -translate-y-1/2">
          <Image
            src="/images/loading/sp-right-line.png"
            width={25}
            height={568.78}
            alt=""
          />
        </div>
      </>
    ) : (
      <>
        <div className="absolute right-[48.2px] top-1/2 -translate-y-1/2 w-[35.07px] h-[892px] max-h-[84vh]">
          <Image
            src="/images/loading/right.png"
            layout="fill"
            objectFit="contain"
            alt=""
          />
        </div>
        <BackgroundUpperRight />
        <BackgroundBottomRight />
      </>
    )}
  </div>
);

const SpBackgroundUpper = () => (
  <div>
    <div className="absolute left-[16px] top-[16px]">
      <Image
        src="/images/loading/sp-upper-left-icon.png"
        width={80}
        height={16}
        alt=""
      />
    </div>
    <div className="absolute left-1/2 -translate-x-1/2 top-[22px] w-[295px]">
      <Image
        src="/images/loading/sp-upper-line.png"
        width={295}
        height={26}
        alt=""
      />
    </div>
  </div>
);

const SpBackgroundBottom = () => (
  <div>
    <div className="absolute left-1/2 -translate-x-1/2 bottom-[20px] w-[279px]">
      <Image
        src="/images/loading/sp-bottom-line.png"
        width={279}
        height={26}
        alt=""
      />
    </div>
    <Barcode isMobile={true} />
  </div>
);

const BackgroundUpperLeft = () => (
  <div>
    <div className="absolute left-[105px] top-[34px]">
      <Image
        src="/images/loading/upper-left-icon.png"
        width={204}
        height={28}
        alt=""
      />
    </div>
    <div className="absolute left-[212px] top-[48px] w-[700px] h-[44.87px]">
      <Image
        src="/images/loading/upper-left-line.png"
        layout="fill"
        objectFit="contain"
        alt=""
      />
    </div>
    <div className="absolute left-[392px] top-[77px]">
      <Image
        src="/images/loading/upper-left-line-mark.png"
        width={190.4}
        height={17}
        alt=""
      />
    </div>
    <SmallCircles isMobile={false} />
    <SmallUpperLeftText isMobile={false} />
  </div>
);

const SmallCircles = ({ isMobile }: { isMobile: boolean }) => (
  <div
    className={`absolute ${
      isMobile ? "left-[49px] top-[155px]" : "left-[204px] top-[288px]"
    }`}
  >
    <Image
      src="/images/loading/upper-left-acc1.png"
      width={isMobile ? 31.37 : 40}
      height={isMobile ? 109 : 139}
      alt=""
    />
  </div>
);

const SmallUpperLeftText = ({ isMobile }: { isMobile: boolean }) => (
  <div
    className={`absolute ${
      isMobile ? "left-[93px] top-[82px]" : "left-[343px] top-[257px]"
    }`}
  >
    <Image
      src="/images/loading/upper-left-text.svg"
      width={65.09}
      height={87.86}
      alt=""
    />
  </div>
);

const BackgroundUpperRight = () => (
  <div>
    <div className="absolute right-[53.28px] top-[34px]">
      <Image
        src="/images/loading/upper-right-icon.png"
        width={287.72}
        height={25.43}
        alt=""
      />
    </div>
    <div className="absolute right-[212px] top-[48px] w-[700px] h-[44.87px]">
      <Image
        src="/images/loading/upper-right-line.png"
        layout="fill"
        objectFit="contain"
        alt=""
      />
    </div>
    <div className="absolute right-[392px] top-[77px]">
      <Image
        src="/images/loading/upper-right-line-mark.png"
        width={190.4}
        height={17}
        alt=""
      />
    </div>
  </div>
);

const BackgroundBottomLeft = () => (
  <div>
    <div className="absolute left-[99px] bottom-[39.75px]">
      <Image
        src="/images/loading/bottom-left-icon.png"
        width={170.55}
        height={22.25}
        alt=""
      />
    </div>
    <div className="absolute left-[212px] bottom-[48px] w-[700px] h-[44.87px]">
      <Image
        src="/images/loading/bottom-left-line.png"
        layout="fill"
        objectFit="contain"
        alt=""
      />
    </div>
    <div className="absolute right-[343.88px] bottom-[83.42px] w-[223.12px]">
      <Image
        src="/images/loading/bottom-left-line-acc1.svg"
        width={223.12}
        height={28.58}
        alt=""
      />
    </div>
    <SmallBottomText isMobile={false} />
    <SmallBottomLeftText isMobile={false} />
  </div>
);

const SmallBottomText = ({ isMobile }: { isMobile: boolean }) => (
  <div
    className={`absolute w-[239.77px] ${
      isMobile
        ? "left-[282px] bottom-[63.39px]"
        : "right-[94.77px] bottom-[83.42px]"
    }`}
  >
    <Image
      src="/images/loading/bottom-left-line-acc2.svg"
      width={239.77}
      height={28.61}
      alt=""
    />
  </div>
);

const SmallBottomLeftText = ({ isMobile }: { isMobile: boolean }) => (
  <div
    className={`absolute ${
      isMobile ? "left-[-22px] bottom-[47px]" : "left-[125px] bottom-[139px]"
    }`}
  >
    <Image
      src="/images/loading/bottom-left-text.svg"
      width={isMobile ? 96.09 : 170}
      height={isMobile ? 130 : 230}
      alt=""
    />
  </div>
);

const BackgroundBottomRight = () => (
  <div>
    <Barcode isMobile={false} />
    <div className="absolute right-[192px] bottom-[48px] w-[700px] h-[44.87px]">
      <Image
        src="/images/loading/bottom-right-line.png"
        layout="fill"
        objectFit="contain"
        alt=""
      />
    </div>
    <div className="absolute right-[407px] bottom-[111.42px]">
      <Image
        src="/images/loading/bottom-right-line-acc1.png"
        width={418}
        height={16.58}
        alt=""
      />
    </div>
    <div className="absolute right-[407px] bottom-[87.92px]">
      <Image
        src="/images/loading/bottom-right-line-acc2.png"
        width={418}
        height={16.58}
        alt=""
      />
    </div>
    <div className="absolute right-[147.74px] bottom-[270.06px]">
      <Image
        src="/images/loading/bottom-right-text.svg"
        width={136.26}
        height={164.94}
        alt=""
      />
    </div>
  </div>
);

const Barcode = ({ isMobile }: { isMobile: boolean }) => (
  <div
    className={`absolute ${
      isMobile ? "right-[16.33px] bottom-[14px]" : "right-[87px] bottom-[34px]"
    }`}
  >
    <Image
      src="/images/loading/bottom-right-icon.png"
      width={isMobile ? 106.67 : 200}
      height={isMobile ? 16 : 30}
      alt=""
    />
  </div>
);

export default Loading;
